<template>
  <NolteDealerFinderDataProvider
    class="nolte-dealer-finder"
    :site-language-code="siteLanguageCode"
    :site-country-code="siteCountryCode"
    #default="{
      searchInput,
      setSearchInput,
      address,
      setAddress,
      dealers,
      distanceUnit,
      countryOptions,
      setCountryByCode,
      countryCode,
      countryName,
      googleMapsApiKey,
    }"
    @dealersChanged="scrollResultListToTop"
  >
    <NolteFormDropdown
      class="nolte-dealer-finder__country-dropdown"
      :class="{ 'nolte-dealer-finder__country-dropdown--loaded': !!countryOptions }"
      :searchable="true"
      :placeholder="$t('select-country')"
      :options="countryOptions"
      :value="countryCode"
      @input="setCountryByCode"
    />

    <div class="nolte-dealer-finder__search">
      <label
        class="nolte-dealer-finder__address-label"
        for="dealerFinderAddressField"
        v-if="countryCode"
      >
        {{ $t('enter-address') }}
      </label>
      <div class="nolte-dealer-finder__search-input-wrapper" v-if="countryCode">
        <!-- TODO: add autocomplete https://stackoverflow.com/a/7399347 -->
        <input
          class="nolte-dealer-finder__search-input"
          type="text"
          autofocus
          :value="searchInput"
          @input="setSearchInput($event.target.value)"
          @keypress.enter="setAddress($event.target.value)"
          @blur="setAddress($event.target.value)"
        />
        <button
          class="nolte-dealer-finder__search-button"
          type="button"
          :disabled="!searchInput || !countryCode"
        >
          <IconSearch />
        </button>
      </div>

      <div v-if="!googleMapsApiKey" class="nolte-dealer-finder__map" />
      <NolteDealerMap
        v-else
        class="nolte-dealer-finder__map"
        ref="dealerMap"
        :current-address="getCurrentAddress(countryName, address)"
        :dealers="dealers"
        :current-marker-id="currentMarkerId"
        :google-maps-api-key="googleMapsApiKey"
        :marker-padding-top="$screen.isDesktop ? 170 : 100"
        @dealerSelected="OnDealerOnMapMarked"
      />
    </div>

    <div class="nolte-dealer-finder__result-list" ref="dealerResultList">
      <ul>
        <li
          class="nolte-dealer-finder__result-item"
          v-for="{ name, street, locality, zip, phone, distance, id } in dealers"
          :class="{ 'nolte-dealer-finder__result-item--current': currentMarkerId === id }"
          :key="id"
          :ref="`dealerResultItem__${id}`"
        >
          <NolteAddress
            :name="name"
            :street="street"
            :locality="locality"
            :zip="zip"
            :country="countryName"
            :phone="phone"
          />
          <NolteButton
            class="nolte-dealer-finder__result-button"
            @click="showDealer(id)"
            type="link-bright"
          >
            {{ distance }}&nbsp;{{ distanceUnit }}
          </NolteButton>
        </li>
      </ul>
    </div>
  </NolteDealerFinderDataProvider>
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill';

import NolteButton from '../NolteButton';
import NolteFormDropdown from '../NolteForm/NolteFormDropdown';
import NolteAddress from '../NolteAddress';

import NolteDealerMap from './NolteDealerMap';
import NolteDealerFinderDataProvider from './NolteDealerFinderDataProvider';
import IconSearch from './search.svg?inline';

import { useScreen } from 'vue-screen';

import { bp768 } from '../../styles/variables.scss';

export default {
  name: 'NolteDealerFinder',

  setup() {
    const screen = useScreen({
      isMobile({ width }) {
        return width <= parseInt(bp768, 10);
      },
      isDesktop({ width }) {
        return width > parseInt(bp768, 10);
      },
    });

    return {
      screen,
    };
  },

  components: {
    NolteButton,
    NolteAddress,
    NolteDealerFinderDataProvider,
    NolteDealerMap,
    NolteFormDropdown,
    IconSearch,
  },

  props: {
    siteLanguageCode: {
      type: String,
      default: undefined,
    },
    siteCountryCode: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      currentMarkerId: undefined,
    };
  },

  mounted() {
    smoothscroll.polyfill();
  },

  methods: {
    getCurrentAddress(countryName, address) {
      if (!countryName && !address) {
        return;
      }

      if (!address) {
        return countryName;
      }
      return `${countryName}, ${address}`;
    },

    setCurrentMarkerId(id) {
      this.currentMarkerId = id;
    },

    scrollResultListToTop() {
      this.$refs?.dealerResultList?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },

    scrollToDealerInList(id) {
      if (this.screen?.isDesktop) {
        // scroll into view on desktop
        const currentDealerListEl = this.$refs[`dealerResultItem__${id}`][0];
        if (currentDealerListEl) {
          this.$refs?.dealerResultList?.scrollTo({
            top: currentDealerListEl.offsetTop,
            behavior: 'smooth',
          });
        }
      }

      if (this.screen?.isMobile) {
        // scroll into view on mobile
        const currentDealerListEl = this.$refs[`dealerResultItem__${id}`][0];
        currentDealerListEl.scrollIntoView({ behavior: 'smooth' });
      }
    },

    scrollToDealerOnMap() {
      if (this.screen.isMobile) {
        // scroll into view on mobile
        this.$refs?.dealerMap?.$el?.scrollIntoView({ behavior: 'smooth' });
      }
    },

    showDealer(id) {
      this.setCurrentMarkerId(id);

      this.scrollToDealerInList(id);
      this.scrollToDealerOnMap();
    },

    OnDealerOnMapMarked(id) {
      this.setCurrentMarkerId(id);

      this.scrollToDealerInList(id);
    },
  },
};
</script>

<style lang="scss" scoped>
$sidebar-breakpoint: $bp-768;

.nolte-dealer-finder {
  position: relative;
  overflow: hidden;

  @include helper__greater($sidebar-breakpoint) {
    height: 80vh;
    display: flex;
    background-color: $color-concret;
  }
}

.nolte-dealer-finder__country-dropdown {
  @include helper__transition(opacity);
  opacity: 0;

  @include helper__until($sidebar-breakpoint) {
    padding-top: $unit-base;
    margin-left: $unit-base;
    margin-right: $unit-base;
    margin-bottom: $unit-base; // overwrite
  }

  @include helper__greater($sidebar-breakpoint) {
    position: absolute;
    top: $unit-double;
    right: $unit-base;
    min-width: 280px;
    z-index: 200;
  }
}

.nolte-dealer-finder__country-dropdown--loaded {
  opacity: 1;
}

.nolte-dealer-finder__search {
  background-image: linear-gradient(
    180deg,
    rgba($color-concret, 0.8) 0%,
    rgba($color-concret, 0) 100%
  );
  position: relative;
  height: 100%;
  width: 67%;

  @include helper__greater-until($sidebar-breakpoint, $bp-1024) {
    position: absolute;
    left: 0;
    width: 100%;
    height: auto;
  }

  @include helper__until($sidebar-breakpoint) {
    z-index: 300;
    padding-top: $unit-half;
    padding-left: $unit-base;
    padding-right: $unit-base;
    width: 100%;
    height: 60vh;
  }

  @include helper__greater($sidebar-breakpoint) {
    z-index: 100;
    flex: 1 auto;
    padding-top: $unit-double;
  }
}

.nolte-dealer-finder__address-label {
  @include text-h2;
  display: block;
  margin-bottom: $unit-half;

  @include helper__until($sidebar-breakpoint) {
    font-size: 22px;
    line-height: (28 / 22); // [Intended lineheight] / [font size]
  }

  @include helper__greater($sidebar-breakpoint) {
    font-size: 32px;
    line-height: (38 / 32); // [Intended lineheight] / [font size]

    padding-left: $unit-base;
    padding-right: $unit-base;
    margin-bottom: $unit-base;
    min-height: $unit-base * 4;
  }
}

.nolte-dealer-finder__search-input-wrapper {
  display: flex;

  @include helper__greater($sidebar-breakpoint) {
    padding-left: $unit-base;
    padding-right: $unit-base;
  }
}

.nolte-dealer-finder__search-input {
  @include helper__input;
  background-color: $color-white;
  box-shadow: 0 6px 25px 0 rgba($color-black, 0.1);
  border-top-left-radius: $radius-default;
  border-bottom-left-radius: $radius-default;
  border-top: 2px solid $color-athens-gray;
  border-left: 2px solid $color-athens-gray;
  border-bottom: 2px solid $color-athens-gray;
  width: 100%;
  padding-left: $unit-half;
  padding-right: $unit-half;
  min-height: 58px; // about the same as the dropdown menu
}

.nolte-dealer-finder__search-button {
  @include helper__button;
  @include helper__transition(background-color, border-color);
  background-color: $color-aureolin;
  border-top-right-radius: $radius-default;
  border-bottom-right-radius: $radius-default;
  border: 2px solid $color-citrine;
  padding-left: 14px;
  padding-right: 14px;
  color: $color-ironside-gray;

  @include helper__greater($sidebar-breakpoint) {
    padding-left: $unit-double;
    padding-right: $unit-double;
  }

  &[disabled] {
    cursor: default;
    pointer-events: none;
    background-color: $color-concret;
    border-color: $color-athens-gray;
  }
}

.nolte-dealer-finder__map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  background-color: $color-athens-gray;

  // shadow from bottom (mobile) or side (tablet + desktop)
  &::before {
    content: '';
    position: absolute;
    pointer-events: none;
    z-index: 50;
  }

  @include helper__greater-until($sidebar-breakpoint, $bp-1024) {
    margin: 0 0 0 33%;
    width: 67%;
    height: 80vh;
  }

  @include helper__until($sidebar-breakpoint) {
    &::before {
      top: 100%;
      left: 50%;
      height: 100%;
      width: 120%;
      transform: translateX(-50%);
      box-shadow: 0 -20px 20px rgba($color-black, 0.05);
    }
  }

  @include helper__greater($sidebar-breakpoint) {
    max-height: 100vh;

    &::before {
      top: 50%;
      height: 120%;
      width: 100%;
      right: 100%;
      transform: translateY(-50%);
      box-shadow: 20px 0 20px rgba($color-black, 0.05);
    }
  }
}

.nolte-dealer-finder__result-list {
  background-color: $color-concret;

  @include helper__greater($sidebar-breakpoint) {
    overflow: auto;
    order: -1;
    width: 33%;
  }

  @include helper__greater-until($sidebar-breakpoint, $bp-1024) {
    margin-top: $unit-double * 6;
  }

  @include helper__greater($bp-1024) {
    flex: 1 auto;
  }
}

.nolte-dealer-finder__result-item {
  @include helper__transition(background-color);
  padding: $unit-base;
}

.nolte-dealer-finder__result-item--current {
  background-color: $color-aureolin;
}

.nolte-dealer-finder__result-button {
  margin-top: $unit-half;
  font-size: inherit;
}

:deep(.nolte-dealer-finder__country-dropdown svg.vs__open-indicator) {
  overflow: visible;
}
</style>
